import { useHover } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useRef } from "react";

import arrow from "@/assets/shared/arrow_gray.svg";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./ShotEditLine.scss";

interface IShotEditLine {
	img: string;
	title: string;
	handleClick: () => void;
	color: "green" | "violet" | "blue";
}

export const ShotEditLine: FC<IShotEditLine> = ({ title, img, handleClick, color }) => {
	const ref = useRef(null);
	const isHovering = useHover(ref);

	return (
		<Flex ref={ref} className={cn("shot-edit full-width cubic-animation", {
			"shot-edit__shadow": isHovering
		})} align="center" justify="space-between">
			<Flex className="gap-xxs" align="center">
				<Flex className={cn("shot-edit__icon", {
					[`shot-edit__icon--${color}`]: true
				})} justify="center" align="center">
					<img src={img} alt={title}/>
				</Flex>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
					{title}
				</Typography.Text>
			</Flex>
			<div onClick={handleClick} role="button" className="pointer">
				<img src={arrow} alt="arrow"/>
			</div>
		</Flex>
	);
};
