import { action } from "@reatom/core";
import { Descendant } from "slate";
import { callErrorAction } from "@/entities/notification";
import { getShotAction, ShotOrder, shotsOrderAtom } from "@/entities/shot-edit";
import { TShotColor } from "@/shared/api/script";
import { GetShotResult, UpdateShotParams, updateStoryboardShotResource } from "@/shared/api/storyboard";
import { getProjectId } from "@/shared/methods/getProjectId.ts";

const getShotId = (): string => {
	const queryString = window.location.pathname;
	const urls = queryString.split("/");
	const shotSelectionIndex = urls.findIndex((value) => value === "shot-selection");
	const shotIdIndex = shotSelectionIndex + 2;

	return urls[shotIdIndex];
};

const getSceneId = () => {
	const queryString = window.location.pathname;
	const urls = queryString.split("/");
	const shotSelectionIndex = urls.findIndex((value) => value === "shot-selection");
	const sceneIdIndex = shotSelectionIndex + 1;

	return urls[sceneIdIndex];
};

const convertShot = (shotsOrder: ShotOrder[], shot: GetShotResult): UpdateShotParams => {
	const shotId = getShotId();
	const sceneId = getSceneId();

	return {
		shot_info: {
			id: shotId,
			scene_id: sceneId,
			title: shot.title,
			prompt: shot.prompt as unknown as Descendant[],
			description: shot.description,
			shot_description: shot.shot_description,
			color: shot.color as unknown as TShotColor,
			location: shot.location,
			dialog: shot.dialog,
			time: shot.time,
			camera_movement: shot.camera_movement,
			camera_angle: shot.camera_angle,
			shot_settings: [],
			shot_characteristics: [],
			props: shot.props

			// characters: shot.characters
			// location_type: shot.locationType,
		} as unknown as UpdateShotParams["shot_info"],
		shots_order: shotsOrder.map((shot) => shot.id)
	};
};

export const updateShotDialogueActions = action(async (ctx, dialogue: string) => {
	try {
		const shot = ctx.get(getShotAction.dataAtom);
		const shots = ctx.get(shotsOrderAtom);
		const projectId = getProjectId();

		if (shot !== null) {
			await updateStoryboardShotResource(
				projectId,
				convertShot(shots, {
					...shot,
					dialog: dialogue
				})
			);
		}
	} catch (e) {
		callErrorAction(ctx, e);
	}
});

export const updateShotDescriptionActions = action(async (ctx, shot_description: string, prompt: Descendant[]) => {
	try {
		const shot = ctx.get(getShotAction.dataAtom);
		const shots = ctx.get(shotsOrderAtom);
		const projectId = getProjectId();

		const newPrompt: Descendant[] = [...prompt, { type: "paragraph", children: [{ text: shot?.description ?? '' }] }];

		if (shot !== null) {
			await updateStoryboardShotResource(
				projectId,
				convertShot(shots, {
					...shot,
					shot_description,
					prompt: newPrompt
				})
			);
		}
	} catch (e) {
		callErrorAction(ctx, e);
	}
});

export const updateShotCameraMovementActions = action(async (ctx, cameraMovement: string) => {
	try {
		const shot = ctx.get(getShotAction.dataAtom);
		const shots = ctx.get(shotsOrderAtom);
		const projectId = getProjectId();

		if (shot !== null) {
			await updateStoryboardShotResource(
				projectId,
				convertShot(shots, {
					...shot,
					camera_movement: cameraMovement
				})
			);
		}
	} catch (e) {
		callErrorAction(ctx, e);
	}
});

export const updateShotPropsActions = action(async (ctx, props: string) => {
	try {
		const shot = ctx.get(getShotAction.dataAtom);
		const shots = ctx.get(shotsOrderAtom);
		const projectId = getProjectId();

		if (shot !== null) {
			await updateStoryboardShotResource(
				projectId,
				convertShot(shots, {
					...shot,
					props
				})
			);
		}
	} catch (e) {
		callErrorAction(ctx, e);
	}
});

export const updateShotCameraAngleActions = action(async (ctx, cameraAngle: string) => {
	try {
		const shot = ctx.get(getShotAction.dataAtom);
		const shots = ctx.get(shotsOrderAtom);
		const projectId = getProjectId();

		if (shot !== null) {
			await updateStoryboardShotResource(
				projectId,
				convertShot(shots, {
					...shot,
					camera_angle: cameraAngle
				})
			);
		}
	} catch (e) {
		callErrorAction(ctx, e);
	}
});
